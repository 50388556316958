import { createMachine, assign, send } from "../../../_snowpack/pkg/xstate.js";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  getIdTokenResult,
  signOut,
} from "../../../_snowpack/pkg/firebase/auth.js";
import { ulid } from "../../../_snowpack/pkg/ulid.js";

//

export default createMachine(
  {
    id: "root",
    initial: "initializing",
    on: {
      UPDATE_AUTH: {
        actions: ["setAuthUser", "setToken"],
        target: "resolvingAuthentication",
      },
      AUTH: {
        target: "authenticating",
      },
      DEAUTH: {
        target: "deauthenticating",
      },
      ADD_ALERT: {
        actions: ["addAlert", "showAlert"],
      },
      REMOVE_ALERT: {
        actions: ["removeAlert"],
      },
    },
    states: {
      initializing: {
        tags: ["loading"],
        entry: "subscribeToAuth",
      },
      resolvingAuthentication: {
        tags: ["loading"],
        invoke: {
          src: "resolveAuthentication",
          onDone: {
            target: "authenticated",
          },
          onError: {
            target: "unauthenticated",
          },
        },
      },
      authenticating: {
        invoke: {
          src: "authenticate",
          onDone: {
            target: "resolvingAuthentication",
          },
          onError: {
            target: "resolvingAuthentication",
            actions: ["showError"],
          },
        },
      },
      deauthenticating: {
        invoke: {
          src: "deauthenticate",
          onDone: {
            target: "resolvingAuthentication",
          },
          onError: {
            target: "resolvingAuthentication",
            actions: ["showError"],
          },
        },
      },
      authenticated: {
        //
      },
      unauthenticated: {
        //
      },
    },
    context: {
      authUser: null,
      token: null,
      alerts: [],
    },
  },
  {
    actions: {
      setAuthUser: assign({
        authUser: (context, event) => {
          return event.data.user;
        },
      }),
      setToken: assign({
        token: (context, event) => {
          return event.data.token;
        },
      }),
      addAlert: assign({
        alerts: (context, event) => {
          const { alert } = event.data;
          return context.alerts.concat(alert);
        },
      }),
      removeAlert: assign({
        alerts: (context, event) => {
          const { alert } = event.data;
          return context.alerts.filter((_alert) => _alert.id !== alert.id);
        },
      }),
      showAlert: (context, event) => {
        /*
            Due to how Shoelace implements toast messaging, it's much easier and sane to dynamically create the toast webcomponent on the fly outside of React and let Shoelace handle the lifecycle. 
            Trying to couple it with React leads to race conditions because Shoelace doesn't expose enough events or methods to do it without hacks. The open props doesn implement the toast layout styles and animations.  
        */

        const { alert, onRemove } = event.data;

        const icon =
          {
            danger: "exclamation-octagon",
            success: "check2-circle",
          }[alert.type] || "info-circle";

        const alertNode = Object.assign(document.createElement("sl-alert"), {
          closable: true,
          type: alert.type || "neutral",
          duration: alert.duration || 3000,
          innerHTML: `
            <sl-icon name="${icon}" slot="icon"></sl-icon>
            ${alert.message}
            `,
        });

        const onRemoveAlert = () => {
          onRemove({ alert });
          alertNode.removeEventListener("sl-after-hide", onRemoveAlert);
        };

        alertNode.addEventListener("sl-after-hide", onRemoveAlert);

        document.body.append(alertNode);

        alertNode.toast && alertNode.toast();
      },
    },
    services: {
      resolveAuthentication(context, event) {
        const { authUser, token } = context;
        const isAuth = [authUser, token].every(Boolean);

        return isAuth ? Promise.resolve() : Promise.reject();
      },
      authenticate(context, event) {
        const { email, password } = event.data;

        // add a safety timeout for the firebase subscription to set things first, before resolving happens
        return signInWithEmailAndPassword(getAuth(), email, password).then(
          (_) => new Promise((resolve) => setTimeout(resolve, 160))
        );
      },
      deauthenticate(context, event) {
        // add a safety timeout for the firebase subscription to set things first, before resolving happens
        return signOut(getAuth()).then(
          (_) => new Promise((resolve) => setTimeout(resolve, 160))
        );
      },
    },
  }
);
