import React, {useContext} from "../../../../_snowpack/pkg/react.js";
import {useMachine} from "../../../../_snowpack/pkg/@xstate/react.js";
import usersMachine from "./usersMachine.js";
import {Link} from "../../../../_snowpack/pkg/react-router-dom.js";
import Container from "../../Common/Container.js";
import {
  SlInput,
  SlButton,
  SlDialog,
  SlIconButton,
  SlTooltip,
  SlIcon
} from "../../../../_snowpack/pkg/@shoelace-style/shoelace/dist/react.js";
import {AppContext} from "../../App/App.js";
export default () => {
  const {token, showAlert} = useContext(AppContext);
  const [state, send] = useMachine(usersMachine, {
    context: {
      authToken: token
    },
    actions: {
      showError: (context, event) => {
        const message = event.data.message;
        showAlert({type: "danger", message});
      },
      showSuccess: (context, event) => {
        const message = event.data.message;
        showAlert({type: "success", message});
      }
    }
  });
  return /* @__PURE__ */ React.createElement(Container, {
    className: "screen users-screen",
    isLoading: state.hasTag("loading")
  }, /* @__PURE__ */ React.createElement("header", null, /* @__PURE__ */ React.createElement("h2", {
    className: "title"
  }, "Uporabniki"), /* @__PURE__ */ React.createElement(SlButton, {
    size: "small",
    onClick: (_) => send("EDIT_USER", {
      data: {user: {}}
    })
  }, "Dodaj uporabnika")), /* @__PURE__ */ React.createElement("section", null, state.context.users.length ? /* @__PURE__ */ React.createElement("table", {
    className: "table users-table"
  }, /* @__PURE__ */ React.createElement("tbody", null, state.context.users.map((user) => {
    return /* @__PURE__ */ React.createElement("tr", {
      key: user.id
    }, /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement(Link, {
      to: `/users/${user.id}`,
      className: "link"
    }, user.last_name, " ", user.first_name)), /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement(SlTooltip, {
      content: "Pošlji sporočilo"
    }, /* @__PURE__ */ React.createElement("a", {
      href: `mailto:${user.email}`,
      className: "link"
    }, user.email))), /* @__PURE__ */ React.createElement("td", null, user.phone ? /* @__PURE__ */ React.createElement(SlTooltip, {
      content: "Pokliči"
    }, /* @__PURE__ */ React.createElement("a", {
      href: `tel:${user.phone}`,
      className: "link"
    }, user.phone)) : "Brez telefonske"), /* @__PURE__ */ React.createElement("td", {
      className: "align-end"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "actions"
    }, user.isNotified && /* @__PURE__ */ React.createElement(SlTooltip, {
      content: "Uporabniku je že bilo poslano vabilo."
    }, /* @__PURE__ */ React.createElement(SlIcon, {
      name: "check-lg"
    })), /* @__PURE__ */ React.createElement(SlTooltip, {
      content: "Odstrani uporabnika"
    }, /* @__PURE__ */ React.createElement(SlIconButton, {
      onClick: (_) => send("CONFIRM_REMOVE_USER", {data: {user}}),
      name: "trash",
      label: "Odstrani uporabnika"
    })), /* @__PURE__ */ React.createElement(SlTooltip, {
      content: "Pošlji vabilo"
    }, /* @__PURE__ */ React.createElement(SlIconButton, {
      onClick: (_) => send("CONFIRM_SEND_INVITE", {
        data: {user}
      }),
      name: "envelope",
      label: "Pošlji vabilo"
    })), /* @__PURE__ */ React.createElement(SlTooltip, {
      content: "Uredi uporabnika"
    }, /* @__PURE__ */ React.createElement(SlIconButton, {
      onClick: (_) => send("EDIT_USER", {
        data: {user}
      }),
      name: "pencil",
      label: "Uredi uporabnika"
    })))));
  }))) : /* @__PURE__ */ React.createElement("div", {
    className: "empty"
  }, "Trenutno ni uporabnikov.")), /* @__PURE__ */ React.createElement(SlDialog, {
    label: state.context.userInput.id ? "Uredi uporabnika" : "Dodaj uporabnika",
    class: "dialog-overview",
    open: state.matches("editingUser"),
    onSlRequestClose: (e) => {
      e.preventDefault();
      send("STOP");
    }
  }, /* @__PURE__ */ React.createElement("form", {
    className: "form",
    onSubmit: (e) => {
      e.preventDefault();
      send("SAVE_USER");
    }
  }, /* @__PURE__ */ React.createElement(SlInput, {
    type: "text",
    label: "Ime",
    value: state.context.userInput.first_name,
    onInput: (e) => send("UPDATE_USER_INPUT", {
      data: {user: {first_name: e.target.value}}
    })
  }), /* @__PURE__ */ React.createElement(SlInput, {
    type: "text",
    label: "Priimek",
    value: state.context.userInput.last_name,
    onInput: (e) => send("UPDATE_USER_INPUT", {
      data: {user: {last_name: e.target.value}}
    })
  }), /* @__PURE__ */ React.createElement(SlInput, {
    type: "email",
    label: "E-naslov",
    value: state.context.userInput.email,
    onInput: (e) => send("UPDATE_USER_INPUT", {
      data: {user: {email: e.target.value}}
    })
  }), /* @__PURE__ */ React.createElement(SlInput, {
    type: "tel",
    label: "Telefon",
    value: state.context.userInput.phone,
    onInput: (e) => send("UPDATE_USER_INPUT", {
      data: {user: {phone: e.target.value}}
    })
  }), /* @__PURE__ */ React.createElement("div", {
    className: "actions"
  }, /* @__PURE__ */ React.createElement(SlButton, {
    onClick: (_) => send("STOP")
  }, "Prekliči"), /* @__PURE__ */ React.createElement(SlButton, {
    variant: "success",
    type: "submit"
  }, "Shrani")))), /* @__PURE__ */ React.createElement(SlDialog, {
    label: "Odstrani uporabnika",
    class: "dialog-overview",
    open: state.matches("removingUser"),
    onSlRequestClose: (e) => {
      e.preventDefault();
      state.matches("removingUser.confirming") && send("STOP");
    }
  }, /* @__PURE__ */ React.createElement("form", {
    className: "form",
    onSubmit: (e) => {
      e.preventDefault();
      send("REMOVE_USER");
    }
  }, /* @__PURE__ */ React.createElement("p", null, "Z odstranitvijo uporabnika se bodo zbrisale tudi vse njihove police in datoteke vezane na police. Ta akcija je nepovrnljiva.", /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement("strong", null, "Ali ste prepričani, da želite odstraniti", " ", state.context.userInput.first_name, " ", state.context.userInput.last_name, "?")), /* @__PURE__ */ React.createElement("div", {
    className: "actions"
  }, state.matches("removingUser.confirming") && /* @__PURE__ */ React.createElement(SlButton, {
    onClick: (_) => send("STOP")
  }, "Prekliči"), /* @__PURE__ */ React.createElement(SlButton, {
    type: "submit",
    variant: "danger",
    loading: state.matches("removingUser.removing")
  }, "Odstrani")))), /* @__PURE__ */ React.createElement(SlDialog, {
    label: "Pošlji vabilo",
    class: "dialog-overview",
    open: state.matches("sendingInvite"),
    onSlRequestClose: (e) => {
      e.preventDefault();
      state.matches("sendingInvite.confirming") && send("STOP");
    }
  }, /* @__PURE__ */ React.createElement("form", {
    className: "form",
    onSubmit: (e) => {
      e.preventDefault();
      send("CONFIRM");
    }
  }, /* @__PURE__ */ React.createElement("p", null, "Poslali boste vabilo na", " ", /* @__PURE__ */ React.createElement("strong", null, state.context.userInput.email), ".", /* @__PURE__ */ React.createElement("br", null), "Ali ste prepričani, da želite nadaljevati?"), /* @__PURE__ */ React.createElement("div", {
    className: "actions"
  }, state.matches("sendingInvite.confirming") && /* @__PURE__ */ React.createElement(SlButton, {
    onClick: (_) => send("STOP")
  }, "Prekliči"), /* @__PURE__ */ React.createElement(SlButton, {
    variant: "success",
    type: "submit",
    loading: state.matches("sendingInvite.sending")
  }, "Pošlji vabilo")))));
};
