import * as __SNOWPACK_ENV__ from '../_snowpack/env.js';

import * as React from "../_snowpack/pkg/react.js";
import {render} from "../_snowpack/pkg/react-dom.js";
import {initializeApp} from "../_snowpack/pkg/firebase/app.js";
import {setBasePath} from "../_snowpack/pkg/@shoelace-style/shoelace/dist/utilities/base-path.js";
setBasePath("/dist/shoelace");
import App from "./components/App/App.js";
initializeApp(__SNOWPACK_ENV__.FIREBASE_CONFIG);
render(/* @__PURE__ */ React.createElement(App, null), document.getElementById("root"));
