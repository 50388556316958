import * as __SNOWPACK_ENV__ from '../../_snowpack/env.js';

const ROOT = __SNOWPACK_ENV__.FUNCTIONS_ROOT;

const ingaAPI = (path = "", params = {}) =>
  fetch(`${ROOT}${path}`, params).then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      return res.json().then((body) => Promise.reject(body)); // allow json responses from errors
    }
  });

// ---- exports

export const getAdminUsers = ({ authToken }) =>
  ingaAPI("/getAdminUsers", {
    method: "get",
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
  });

export const createAdminUser = ({ authToken, email, password, name }) =>
  ingaAPI("/createAdminUser", {
    method: "post",
    body: JSON.stringify({ email, password, name }),
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
  });

export const removeAdminUser = ({ authToken, id }) =>
  ingaAPI("/removeAdminUser", {
    method: "delete",
    body: JSON.stringify({ id }),
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
  });

export const createUser = ({
  authToken,
  email,
  phone,
  first_name,
  last_name,
}) =>
  ingaAPI("/createUser", {
    method: "post",
    body: JSON.stringify({ email, phone, first_name, last_name }),
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
  });

export const updateUser = ({
  authToken,
  id,
  email,
  phone,
  first_name,
  last_name,
}) =>
  ingaAPI("/updateUser", {
    method: "put",
    body: JSON.stringify({ id, email, phone, first_name, last_name }),
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
  });

export const removeUser = ({ authToken, id }) =>
  ingaAPI("/removeUser", {
    method: "delete",
    body: JSON.stringify({ id }),
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
  });

export const sendInviteEmail = ({ authToken, email, app_url, first_name }) =>
  ingaAPI("/sendInviteEmail", {
    method: "post",
    body: JSON.stringify({ email, first_name, app_url }),
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${authToken}`,
    },
  });
