import React from "../../../_snowpack/pkg/react.js";
import {SlButton} from "../../../_snowpack/pkg/@shoelace-style/shoelace/dist/react.js";
export default ({
  buttonProps = {},
  buttonLabel = "Select",
  multiple = false,
  onInput
} = {}) => {
  const refs = {
    fileInput: null
  };
  const onClick = () => {
    refs.fileInput && refs.fileInput.click();
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(SlButton, {
    ...buttonProps,
    onClick: (_) => onClick()
  }, buttonLabel), /* @__PURE__ */ React.createElement("input", {
    type: "file",
    multiple,
    style: {display: "none"},
    ref: (node) => refs.fileInput = node,
    onInput: (e) => onInput(Array.from(e.target.files))
  }));
};
