import React, {createContext} from "../../../_snowpack/pkg/react.js";
import {useMachine} from "../../../_snowpack/pkg/@xstate/react.js";
import {ulid} from "../../../_snowpack/pkg/ulid.js";
import appMachine from "./appMachine.js";
import {
  getAuth,
  onAuthStateChanged,
  getIdTokenResult,
  signInWithEmailAndPassword,
  signOut
} from "../../../_snowpack/pkg/firebase/auth.js";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  NavLink,
  Link
} from "../../../_snowpack/pkg/react-router-dom.js";
import Container from "../Common/Container.js";
import AuthScreen from "../Auth/AuthScreen/AuthScreen.js";
import UsersScreen from "../User/UsersScreen/UsersScreen.js";
import UserDetailScreen from "../User/UserDetailScreen/UserDetailScreen.js";
import SettingsScreen from "../Settings/SettingsScreen.js";
export const AppContext = createContext();
export default () => {
  const showAlert = (params = {}) => send("ADD_ALERT", {
    data: {
      onRemove: ({alert}) => send("REMOVE_ALERT", {data: {alert}}),
      alert: {
        ...params,
        id: ulid()
      }
    }
  });
  const [state, send] = useMachine(appMachine, {
    actions: {
      showError: (context, event) => {
        const message = event.data.message;
        showAlert({type: "danger", message});
      },
      subscribeToAuth(context, event) {
        onAuthStateChanged(getAuth(), (user) => {
          return (user ? getIdTokenResult(user, true).then(({token, claims}) => {
            if (!claims.isAdmin) {
              return signOut(getAuth()).then((_) => Promise.reject());
            } else {
              send("UPDATE_AUTH", {data: {user, token}});
            }
          }) : Promise.reject()).catch((_) => {
            send("UPDATE_AUTH", {data: {user: null, token: null}});
          });
        });
      }
    }
  });
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Container, {
    isLoading: state.hasTag("loading")
  }, /* @__PURE__ */ React.createElement(AppContext.Provider, {
    value: {token: state.context.token, showAlert}
  }, state.matches("authenticated") && /* @__PURE__ */ React.createElement(Router, null, /* @__PURE__ */ React.createElement("header", null, /* @__PURE__ */ React.createElement("div", {
    className: "wrap"
  }, /* @__PURE__ */ React.createElement(Link, {
    to: "/"
  }, /* @__PURE__ */ React.createElement("h2", {
    className: "logo"
  }, /* @__PURE__ */ React.createElement("img", {
    src: "/images/logo-light.png"
  }))), /* @__PURE__ */ React.createElement("nav", null, /* @__PURE__ */ React.createElement(NavLink, {
    to: "/users"
  }, "Uporabniki"), /* @__PURE__ */ React.createElement(NavLink, {
    to: "/settings"
  }, "Nastavitve"), /* @__PURE__ */ React.createElement("a", {
    onClick: (_) => send("DEAUTH")
  }, "Odjava")))), /* @__PURE__ */ React.createElement("div", {
    className: "body"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "wrap"
  }, /* @__PURE__ */ React.createElement(Switch, null, /* @__PURE__ */ React.createElement(Route, {
    exact: true,
    path: "/"
  }, /* @__PURE__ */ React.createElement(Redirect, {
    to: {
      pathname: "/users"
    }
  })), /* @__PURE__ */ React.createElement(Route, {
    exact: true,
    path: "/users"
  }, /* @__PURE__ */ React.createElement(UsersScreen, null)), /* @__PURE__ */ React.createElement(Route, {
    exact: true,
    path: "/users/:user_id"
  }, /* @__PURE__ */ React.createElement(UserDetailScreen, null)), /* @__PURE__ */ React.createElement(Route, {
    exact: true,
    path: "/settings"
  }, /* @__PURE__ */ React.createElement(SettingsScreen, null)))))), (state.matches("unauthenticated") || state.matches("authenticating")) && /* @__PURE__ */ React.createElement(AuthScreen, {
    isLoading: state.matches("authenticating"),
    onSubmit: ({email, password}) => send("AUTH", {data: {email, password}})
  }))));
};
