import React, {useContext, useRef} from "../../../_snowpack/pkg/react.js";
import {useMachine} from "../../../_snowpack/pkg/@xstate/react.js";
import settingsMachine from "./settingsMachine.js";
import Container from "../Common/Container.js";
import {
  SlInput,
  SlButton,
  SlDialog,
  SlIconButton,
  SlTooltip
} from "../../../_snowpack/pkg/@shoelace-style/shoelace/dist/react.js";
import {AppContext} from "../App/App.js";
export default () => {
  const {token, showAlert} = useContext(AppContext);
  const [state, send] = useMachine(settingsMachine, {
    context: {
      authToken: token
    },
    actions: {
      showError: (context, event) => {
        const message = event.data.message;
        showAlert({type: "danger", message});
      }
    }
  });
  return /* @__PURE__ */ React.createElement(Container, {
    className: "screen users-screen",
    isLoading: state.hasTag("loading")
  }, /* @__PURE__ */ React.createElement("header", null, /* @__PURE__ */ React.createElement("h2", {
    className: "title"
  }, "Nastavitve")), /* @__PURE__ */ React.createElement("section", null, /* @__PURE__ */ React.createElement("header", null, /* @__PURE__ */ React.createElement("h2", {
    className: "title"
  }, "Administratorji"), /* @__PURE__ */ React.createElement("div", {
    className: "actions"
  }, /* @__PURE__ */ React.createElement(SlButton, {
    size: "small",
    onClick: (_) => send("EDIT_ADMIN_USER", {data: {adminUser: {}}})
  }, "Dodaj administratorja"))), state.context.adminUsers.length ? /* @__PURE__ */ React.createElement("table", {
    className: "table"
  }, /* @__PURE__ */ React.createElement("tbody", null, state.context.adminUsers.map((adminUser) => {
    return /* @__PURE__ */ React.createElement("tr", {
      key: adminUser.id
    }, /* @__PURE__ */ React.createElement("td", null, adminUser.name), /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement(SlTooltip, {
      content: "Poslji sporocilo"
    }, /* @__PURE__ */ React.createElement("a", {
      href: `mailto:${adminUser.email}`,
      className: "link"
    }, adminUser.email))), /* @__PURE__ */ React.createElement("td", {
      className: "align-end"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "actions"
    }, /* @__PURE__ */ React.createElement(SlTooltip, {
      content: "Odstrani administratorja"
    }, /* @__PURE__ */ React.createElement(SlIconButton, {
      onClick: (_) => send("CONFIRM_REMOVE_ADMIN_USER", {
        data: {adminUser}
      }),
      name: "trash",
      label: "Odstrani administratorja"
    })))));
  }))) : /* @__PURE__ */ React.createElement("div", {
    className: "empty"
  }, "Trenutno ni administratorjev.")), /* @__PURE__ */ React.createElement(SlDialog, {
    label: "Dodaj administratorja",
    class: "dialog-overview",
    open: state.matches("editingAdminUser"),
    onSlRequestClose: (e) => {
      e.preventDefault();
      state.matches("editingAdminUser.idle") && send("STOP");
    }
  }, /* @__PURE__ */ React.createElement("form", {
    className: "form",
    onSubmit: (e) => {
      e.preventDefault();
      send("SAVE_ADMIN_USER");
    }
  }, /* @__PURE__ */ React.createElement(SlInput, {
    type: "text",
    label: "Ime",
    value: state.context.adminUserInput.name,
    onInput: (e) => send("UPDATE_ADMIN_USER_INPUT", {
      data: {adminUser: {name: e.target.value}}
    })
  }), /* @__PURE__ */ React.createElement(SlInput, {
    type: "email",
    label: "E-naslov",
    value: state.context.adminUserInput.email,
    onInput: (e) => send("UPDATE_ADMIN_USER_INPUT", {
      data: {adminUser: {email: e.target.value}}
    })
  }), /* @__PURE__ */ React.createElement(SlInput, {
    type: "password",
    label: "Geslo",
    value: state.context.adminUserInput.password,
    "help-text": "Mora vsebovati vsaj 6 znakov",
    onInput: (e) => send("UPDATE_ADMIN_USER_INPUT", {
      data: {adminUser: {password: e.target.value}}
    })
  }), /* @__PURE__ */ React.createElement("div", {
    className: "actions"
  }, /* @__PURE__ */ React.createElement(SlButton, {
    onClick: (_) => send("STOP"),
    disabled: state.matches("editingAdminUser.saving")
  }, "Preklici"), /* @__PURE__ */ React.createElement(SlButton, {
    variant: "success",
    type: "submit",
    loading: state.matches("editingAdminUser.saving")
  }, "Shrani")))), /* @__PURE__ */ React.createElement(SlDialog, {
    label: "Odstrani administratorja",
    class: "dialog-overview",
    open: state.matches("removingAdminUser"),
    onSlRequestClose: (e) => {
      e.preventDefault();
      state.matches("removingAdminUser.confirming") && send("STOP");
    }
  }, /* @__PURE__ */ React.createElement("form", {
    className: "form",
    onSubmit: (e) => {
      e.preventDefault();
      send("REMOVE_USER");
    }
  }, /* @__PURE__ */ React.createElement("p", null, "Odstranjen administrator bo izgubil dostop do Inga administracije. Lahko mu ga povrnete tako, da na novo dodate enakega uporabnika z istimi prijavnimi podatki.", /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement("strong", null, "Ali ste prepričani, da želite odstraniti", " ", state.context.adminUserInput.name, "?")), /* @__PURE__ */ React.createElement("div", {
    className: "actions"
  }, state.matches("removingAdminUser.confirming") && /* @__PURE__ */ React.createElement(SlButton, {
    onClick: (_) => send("STOP")
  }, "Preklici"), /* @__PURE__ */ React.createElement(SlButton, {
    variant: "danger",
    type: "submit",
    loading: state.matches("removingAdminUser.removing")
  }, "Odstrani")))));
};
