export const POLICY_TYPES = [
  {
    type: 'car',
    title: 'Avtomobilsko',
  },
  {
    type: 'home',
    title: 'Dom',
  },
  {
    type: 'health',
    title: 'Zdravje',
  },
  {
    type: 'accident',
    title: 'Nezgoda',
  },
  {
    type: 'life',
    title: 'Življenjsko',
  },
  {
    type: 'investment',
    title: 'Naložba',
  },
  {
    type: 'travel',
    title: 'Tujina',
  },
  {
    type: 'other-type',
    title: 'Ostalo',
  },
];
