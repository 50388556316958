import { createMachine, assign } from "../../../../_snowpack/pkg/xstate.js";
import { orderBy } from "../../../../_snowpack/pkg/lodash.js";
import { ulid } from "../../../../_snowpack/pkg/ulid.js";
import dayjs from "../../../../_snowpack/pkg/dayjs.js";

import {
  getFirestore,
  collection,
  doc,
  getDocs,
  getDoc,
  setDoc,
  deleteDoc,
  arrayUnion,
  arrayRemove,
} from "../../../../_snowpack/pkg/firebase/firestore.js";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "../../../../_snowpack/pkg/firebase/storage.js";

//

export default createMachine(
  {
    id: "root",
    initial: "initializing",
    states: {
      idle: {
        on: {
          EDIT_POLICY: {
            target: "editingPolicy",
            actions: ["resetPolicyInput", "setPolicyInput"],
          },
          CONFIRM_REMOVE_POLICY: {
            target: "removingPolicy",
            actions: ["setPolicyInput"],
          },
          ADD_FILES_TO_POLICY: {
            target: "addingFilesToPolicy",
          },
          CONFIRM_REMOVE_FILE: {
            target: "removingFileFromPolicy",
            actions: ["setPolicyInput", "setFileInput"],
          },
          DOWNLOAD_FILE: {
            actions: ["downloadFile"],
          },
          TOGGLE_IS_POLICY: {
            target: "togglingIsPolicy",
          },
        },
      },
      initializing: {
        tags: ["loading"],
        invoke: {
          src: "getUserID",
          onDone: {
            target: "fetchingResources",
            actions: ["setUser"],
          },
          onError: {
            target: "idle",
            actions: ["showError"],
          },
        },
      },
      fetchingResources: {
        tags: ["loading"],
        invoke: {
          src: "getResources",
          onDone: {
            target: "idle",
            actions: ["setUser", "setPolicies"],
          },
          onError: {
            target: "idle",
            actions: ["showError"],
          },
        },
      },
      editingPolicy: {
        on: {
          STOP: {
            target: "idle",
          },
          UPDATE_POLICY_INPUT: {
            actions: ["setPolicyInput"],
          },
          SAVE_POLICY: {
            target: "savingPolicy",
          },
        },
      },
      savingPolicy: {
        invoke: {
          src: "savePolicy",
          onDone: {
            target: "refreshingPolicies",
          },
          onError: {
            target: "#root.idle",
            actions: ["showError"],
          },
        },
      },
      removingPolicy: {
        initial: "confirming",
        states: {
          confirming: {
            on: {
              STOP: {
                target: "#root.idle",
              },
              REMOVE_POLICY: {
                target: "removing",
              },
            },
          },
          removing: {
            invoke: {
              src: "removePolicy",
              onDone: {
                target: "#root.refreshingPolicies",
              },
              onError: {
                target: "confirming",
                actions: ["showError"],
              },
            },
          },
        },
      },
      togglingIsPolicy: {
        invoke: {
          src: "toggleIsPolicy",
          onDone: {
            target: "refreshingPolicies",
          },
          onError: {
            target: "#root.idle",
            actions: ["showError"],
          },
        },
      },
      refreshingPolicies: {
        invoke: {
          src: "getPolicies",
          onDone: {
            target: "idle",
            actions: ["setPolicies", "resetPolicyInput"],
          },
          onError: {
            target: "idle",
            actions: ["showError"],
          },
        },
      },
      addingFilesToPolicy: {
        invoke: {
          src: "addFilesToPolicy",
          onDone: {
            target: "refreshingPolicies",
          },
          onError: {
            target: "#root.idle",
            actions: ["showError"],
          },
        },
      },
      removingFileFromPolicy: {
        initial: "confirming",
        states: {
          confirming: {
            on: {
              STOP: {
                target: "#root.idle",
              },
              REMOVE_FILE: {
                target: "removing",
              },
            },
          },
          removing: {
            invoke: {
              src: "removeFileFromPolicy",
              onDone: {
                target: "#root.refreshingPolicies",
                actions: ["resetPolicyInput", "resetFileInput"],
              },
              onError: {
                target: "confirming",
                actions: ["showError"],
              },
            },
          },
        },
      },
    },
    context: {
      user: {},
      policies: [],
      policyInput: {
        type: "",
        insurance_company: "",
      },
      fileInput: {},
    },
  },
  {
    actions: {
      setUser: assign({
        user(context, event) {
          const { user } = event.data;
          return user;
        },
      }),
      setPolicies: assign({
        policies(context, event) {
          const { policies } = event.data;
          return orderBy(policies, ["insurance_company", "type"]);
        },
      }),
      setPolicyInput: assign({
        policyInput(context, event) {
          return {
            ...context.policyInput,
            ...event.data.policy,
          };
        },
      }),
      resetPolicyInput: assign({
        policyInput(context, event) {
          return {
            type: "",
            insurance_company: "",
          };
        },
      }),
      setFileInput: assign({
        fileInput(context, event) {
          return {
            ...context.fileInput,
            ...event.data.file,
          };
        },
      }),
      resetFileInput: assign({
        fileInput(context, event) {
          return {};
        },
      }),
      downloadFile: (context, event) => {
        const file = event.data.file;

        getDownloadURL(ref(getStorage(), file.storagePath)).then((url) => {
          const tempNode = document.createElement("a");
          tempNode.href = url;
          tempNode.download = file.name;
          tempNode.style.display = "none";

          document.body.append(tempNode);
          tempNode.click();
          tempNode.remove();
        });
      },
    },
    services: {
      getResources(context, event) {
        const { id } = context.user;
        const db = getFirestore();
        return Promise.all([
          getDoc(doc(db, `users/${id}`)).then((doc) => {
            return {
              id: doc.id,
              ...doc.data(),
            };
          }),
          getDocs(collection(db, `users/${id}/policies`)).then((snapshot) =>
            snapshot.docs.map((doc) => {
              return {
                id: doc.id,
                ...doc.data(),
              };
            })
          ),
        ]).then(([user, policies]) => {
          return {
            user,
            policies,
          };
        });
      },
      savePolicy(context, event) {
        const { policyInput, user } = context;
        const policyID = policyInput.id || ulid();

        const policyRef = doc(
          getFirestore(),
          `users/${user.id}/policies/${policyID}`
        );

        return setDoc(policyRef, policyInput, { merge: true })
          .then((_) => {
            return {
              id: policyID,
              ...policyInput,
            };
          })
          .then((policy) => ({ policy }));
      },
      getPolicies(context, event) {
        const user = context.user;

        const policiesRef = collection(
          getFirestore(),
          `users/${user.id}/policies`
        );

        return getDocs(policiesRef)
          .then((snapshot) =>
            snapshot.docs.map((doc) => {
              return {
                id: doc.id,
                ...doc.data(),
              };
            })
          )
          .then((policies) => ({ policies }));
      },
      removePolicy(context, event) {
        const { policyInput, user } = context;

        const policyRef = doc(
          getFirestore(),
          `users/${user.id}/policies/${policyInput.id}`
        );

        if (policyInput.files) {
          return Promise.all(
            policyInput.files.map((file) => {
              const fileRef = ref(getStorage(), file.storagePath);
              return deleteObject(fileRef);
            })
          ).then((_) => {
            return deleteDoc(policyRef);
          });
        } else {
          return deleteDoc(policyRef);
        }
      },
      addFilesToPolicy(context, event) {
        const user = context.user;
        const policy = event.data.policy;
        const files = event.data.files;

        const policyFilesRef = ref(getStorage(), `${user.id}/${policy.id}`);

        return Promise.all(
          files.map((file) => {
            const fileRef = ref(policyFilesRef, file.name);
            return uploadBytes(fileRef, file, {
              contentType: file.type,
              contentDisposition: `attachment; filename="${file.name}"`,
              customMetadata: {
                owner: `${user.first_name} ${user.last_name}`,
                insurance_company: policy.insurance_company,
                policy_type: policy.type,
              },
            }).then((_) => {
              return {
                name: file.name,
                type: file.type,
                size: file.size,
                storagePath: fileRef.fullPath,
                created_at: dayjs().toISOString(),
                isPolicy: false,
              };
            });
          })
        ).then((fileMetas) => {
          const policyRef = doc(
            getFirestore(),
            `users/${user.id}/policies/${policy.id}`
          );

          return setDoc(
            policyRef,
            {
              files: arrayUnion(...fileMetas),
            },
            { merge: true }
          );
        });
      },
      removeFileFromPolicy(context, event) {
        const user = context.user;
        const policy = context.policyInput;
        const file = context.fileInput;

        const policyRef = doc(
          getFirestore(),
          `users/${user.id}/policies/${policy.id}`
        );
        const fileRef = ref(getStorage(), file.storagePath);

        return Promise.all([
          setDoc(policyRef, { files: arrayRemove(file) }, { merge: true }),
          deleteObject(fileRef),
        ]);
      },
      async toggleIsPolicy(context, event) {
        const user = context.user;
        const policy = event.data.policy;
        const file = event.data.file;

        const allFiles = policy.files.map((_file) =>
          _file.id === file.id
            ? {
                ..._file,
                isPolicy: !_file.isPolicy,
              }
            : _file
        );

        const policyRef = doc(
          getFirestore(),
          `users/${user.id}/policies/${policy.id}`
        );

        return setDoc(
          policyRef,
          {
            files: allFiles,
          },
          { merge: true }
        );
      },
    },
  }
);
