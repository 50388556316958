import React, {useState} from "../../../../_snowpack/pkg/react.js";
import {SlInput, SlButton} from "../../../../_snowpack/pkg/@shoelace-style/shoelace/dist/react.js";
export default ({onSubmit, isLoading = false} = {}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  return /* @__PURE__ */ React.createElement("div", {
    className: "auth-screen"
  }, /* @__PURE__ */ React.createElement("form", {
    onSubmit: (e) => {
      e.preventDefault();
      onSubmit({email, password});
    }
  }, /* @__PURE__ */ React.createElement("h2", {
    className: "title"
  }, /* @__PURE__ */ React.createElement("img", {
    src: "/images/logo-dark.png"
  }), /* @__PURE__ */ React.createElement("span", null, "Administracija")), /* @__PURE__ */ React.createElement(SlInput, {
    name: "email",
    type: "email",
    label: "Email",
    value: email,
    onSlInput: (e) => setEmail(e.target.value)
  }), /* @__PURE__ */ React.createElement(SlInput, {
    name: "password",
    type: "password",
    label: "Password",
    value: password,
    onSlInput: (e) => setPassword(e.target.value)
  }), /* @__PURE__ */ React.createElement(SlButton, {
    type: "submit",
    variant: "success",
    disabled: !(email && password),
    loading: isLoading
  }, "Prijava")));
};
