import * as __SNOWPACK_ENV__ from '../../../../_snowpack/env.js';

import { createMachine, assign } from '../../../../_snowpack/pkg/xstate.js';
import { orderBy } from '../../../../_snowpack/pkg/lodash.js';
import { ulid } from '../../../../_snowpack/pkg/ulid.js';
import dayjs from '../../../../_snowpack/pkg/dayjs.js';

import {
  getFirestore,
  collection,
  doc,
  getDocs,
  getDoc,
  setDoc,
  deleteDoc,
} from '../../../../_snowpack/pkg/firebase/firestore.js';
import { getStorage, ref, deleteObject } from '../../../../_snowpack/pkg/firebase/storage.js';

import {
  sendInviteEmail,
  createUser,
  updateUser,
  removeUser,
} from '../../../providers/inga-functions.js';

//

export default createMachine(
  {
    id: 'root',
    initial: 'initializing',
    states: {
      idle: {
        on: {
          EDIT_USER: {
            target: 'editingUser',
            actions: ['resetUserInput', 'setUserInput'],
          },
          CONFIRM_REMOVE_USER: {
            target: 'removingUser',
            actions: ['resetUserInput', 'setUserInput'],
          },
          CONFIRM_SEND_INVITE: {
            target: 'sendingInvite',
            actions: ['resetUserInput', 'setUserInput'],
          },
        },
      },
      initializing: {
        tags: ['loading'],
        invoke: {
          src: 'getUsers',
          onDone: {
            target: 'idle',
            actions: ['setUsers'],
          },
          onError: {
            target: 'idle',
            actions: ['showError'],
          },
        },
      },
      refreshingUsers: {
        invoke: {
          src: 'getUsers',
          onDone: {
            target: 'idle',
            actions: ['setUsers', 'resetUserInput'],
          },
          onError: {
            target: 'idle',
            actions: ['showError'],
          },
        },
      },
      editingUser: {
        on: {
          STOP: {
            target: 'idle',
          },
          UPDATE_USER_INPUT: {
            actions: ['setUserInput'],
          },
          SAVE_USER: {
            target: 'savingUser',
          },
        },
      },
      savingUser: {
        invoke: {
          src: 'saveUser',
          onDone: {
            target: '#root.refreshingUsers',
          },
          onError: {
            target: '#root.idle',
            actions: ['showError'],
          },
        },
      },
      removingUser: {
        initial: 'confirming',
        states: {
          confirming: {
            on: {
              STOP: {
                target: '#root.idle',
              },
              REMOVE_USER: {
                target: 'removing',
              },
            },
          },
          removing: {
            invoke: {
              src: 'removeUser',
              onDone: {
                target: '#root.refreshingUsers',
              },
              onError: {
                target: 'confirming',
                actions: ['showError'],
              },
            },
          },
        },
      },
      sendingInvite: {
        initial: 'confirming',
        states: {
          confirming: {
            on: {
              STOP: {
                target: '#root.idle',
              },
              CONFIRM: {
                target: 'sending',
              },
            },
          },
          sending: {
            invoke: {
              src: 'sendInviteEmail',
              onDone: {
                target: '#root.refreshingUsers',
                actions: ['showSuccess'],
              },
              onError: {
                target: 'confirming',
                actions: ['showError'],
              },
            },
          },
        },
      },
    },
    context: {
      users: [],
      userInput: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
      },
    },
  },
  {
    actions: {
      setUsers: assign({
        users(context, event) {
          const { users } = event.data;
          return orderBy(users, (user) => user.last_name, 'asc');
        },
      }),
      setUserInput: assign({
        userInput(context, event) {
          return {
            ...context.userInput,
            ...event.data.user,
          };
        },
      }),
      resetUserInput: assign({
        userInput(context, event) {
          return {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
          };
        },
      }),
    },
    services: {
      getUsers(context, event) {
        return getDocs(collection(getFirestore(), 'users')).then((snapshot) => {
          return {
            users: snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
          };
        });
      },
      saveUser(context, event) {
        const authToken = context.authToken;
        const { userInput } = context;
        const userID = userInput.id;

        if (userID) {
          return updateUser({ authToken, id: userID, ...userInput }).then(
            (_) => {
              const userRef = doc(getFirestore(), `users/${userID}`);

              return setDoc(userRef, userInput, { merge: true })
                .then((_) => {
                  return {
                    id: userID,
                    ...userInput,
                  };
                })
                .then((user) => ({ user }));
            }
          );
        } else {
          return createUser({
            authToken,
            ...userInput,
          }).then(({ data: { id: userID } }) => {
            const userRef = doc(getFirestore(), `users/${userID}`);

            return setDoc(
              userRef,
              { ...userInput, isNotified: false },
              { merge: true }
            )
              .then((_) => {
                return {
                  id: userID,
                  ...userInput,
                };
              })
              .then((user) => ({ user }));
          });
        }
      },
      removeUser(context, event) {
        const authToken = context.authToken;
        const user = context.userInput;

        const db = getFirestore();
        const userRef = doc(db, `users/${user.id}`);
        const policiesRef = collection(db, `users/${user.id}/policies`);

        return getDocs(policiesRef)
          .then((snapshot) =>
            snapshot.docs.map((doc) => {
              return {
                id: doc.id,
                ...doc.data(),
              };
            })
          )
          .then((policies) => {
            if (!policies.length) return;

            return Promise.all(
              policies.map((policy) => {
                const policyRef = doc(
                  db,
                  `users/${user.id}/policies/${policy.id}`
                );

                // delete all files of the property
                return Promise.all(
                  policy.files
                    ? policy.files.map((file) => {
                        const fileRef = ref(getStorage(), file.storagePath);
                        return deleteObject(fileRef);
                      })
                    : []
                ).then((_) => {
                  // delete the policy, once files are deleted
                  return deleteDoc(policyRef);
                });
              })
            );
          })
          .then((_) => {
            // delete the user
            return removeUser({ authToken, id: user.id }).then((_) => {
              return deleteDoc(userRef);
            });
          });
      },
      sendInviteEmail(context, event) {
        const user = context.userInput;
        const authToken = context.authToken;
        const app_url = __SNOWPACK_ENV__.APP_ROOT;

        !user.isNotified &&
          updateUser({ authToken, id: user.id, ...user }).then((_) => {
            const userRef = doc(getFirestore(), `users/${user.id}`);

            return setDoc(userRef, { isNotified: true }, { merge: true });
          });

        return sendInviteEmail({ authToken, app_url, ...user }).then((_) => ({
          message: 'Vabilo poslano.',
        }));
      },
    },
  }
);
