import { createMachine, assign } from "../../../_snowpack/pkg/xstate.js";
import { orderBy } from "../../../_snowpack/pkg/lodash.js";

import {
  getAdminUsers,
  createAdminUser,
  removeAdminUser,
} from "../../providers/inga-functions.js";

//

export default createMachine(
  {
    id: "root",
    initial: "initializing",
    states: {
      idle: {
        on: {
          EDIT_ADMIN_USER: {
            target: "editingAdminUser",
            actions: ["resetAdminUserInput", "setAdminUserInput"],
          },
          CONFIRM_REMOVE_ADMIN_USER: {
            target: "removingAdminUser",
            actions: ["resetAdminUserInput", "setAdminUserInput"],
          },
        },
      },
      initializing: {
        tags: ["loading"],
        invoke: {
          src: "getResources",
          onDone: {
            target: "idle",
            actions: ["setAdminUsers"],
          },
          onError: {
            target: "idle",
            actions: ["showError"],
          },
        },
      },

      // ----  admin users
      refreshingAdminUsers: {
        tags: ["loading-admin-users"],
        invoke: {
          src: "getAdminUsers",
          onDone: {
            target: "idle",
            actions: ["setAdminUsers", "resetAdminUserInput"],
          },
          onError: {
            target: "idle",
            actions: ["showError"],
          },
        },
      },
      editingAdminUser: {
        initial: "idle",
        states: {
          idle: {
            on: {
              STOP: {
                target: "#root.idle",
              },
              UPDATE_ADMIN_USER_INPUT: {
                actions: ["setAdminUserInput"],
              },
              SAVE_ADMIN_USER: {
                target: "saving",
              },
            },
          },
          saving: {
            invoke: {
              src: "saveAdminUser",
              onDone: {
                target: "#root.refreshingAdminUsers",
              },
              onError: {
                target: "idle",
                actions: ["showError"],
              },
            },
          },
        },
      },

      removingAdminUser: {
        initial: "confirming",
        states: {
          confirming: {
            on: {
              STOP: {
                target: "#root.idle",
              },
              REMOVE_USER: {
                target: "removing",
              },
            },
          },
          removing: {
            invoke: {
              src: "removeAdminUser",
              onDone: {
                target: "#root.refreshingAdminUsers",
              },
              onError: {
                target: "confirming",
                actions: ["showError"],
              },
            },
          },
        },
      },
    },
    context: {
      adminUsers: [],
      adminUserInput: {
        name: "",
        email: "",
        password: "",
      },
    },
  },
  {
    actions: {
      setAdminUsers: assign({
        adminUsers(context, event) {
          const { adminUsers } = event.data;
          return orderBy(
            adminUsers,
            (user) => String(user.name).toLowerCase(),
            "asc"
          );
        },
      }),
      setAdminUserInput: assign({
        adminUserInput(context, event) {
          return {
            ...context.adminUserInput,
            ...event.data.adminUser,
          };
        },
      }),
      resetAdminUserInput: assign({
        adminUserInput(context, event) {
          return {
            name: "",
            email: "",
            password: "",
          };
        },
      }),
    },
    services: {
      getResources(context, event) {
        const { authToken } = context;

        return Promise.all([getAdminUsers({ authToken })]).then(
          ([{ data: adminUsers }]) => {
            return {
              adminUsers,
            };
          }
        );
      },
      getAdminUsers(context, event) {
        const { authToken } = context;

        return getAdminUsers({ authToken }).then(({ data: adminUsers }) => {
          return {
            adminUsers,
          };
        });
      },
      saveAdminUser(context, event) {
        const { authToken } = context;
        const { name, email, password } = context.adminUserInput;

        return createAdminUser({ authToken, name, email, password });
      },
      removeAdminUser(context, event) {
        const { authToken } = context;
        const { id } = context.adminUserInput;

        return removeAdminUser({ authToken, id });
      },
    },
  }
);
