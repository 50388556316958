import React, {useContext, Fragment} from "../../../../_snowpack/pkg/react.js";
import {useMachine} from "../../../../_snowpack/pkg/@xstate/react.js";
import userDetailMachine from "./userDetailMachine.js";
import dayjs from "../../../../_snowpack/pkg/dayjs.js";
import relativeTime from "../../../../_snowpack/pkg/dayjs/plugin/relativeTime.js";
dayjs.extend(relativeTime);
import {useParams} from "../../../../_snowpack/pkg/react-router-dom.js";
import {
  SlInput,
  SlSelect,
  SlCheckbox,
  SlOption,
  SlButton,
  SlButtonGroup,
  SlDialog,
  SlIcon,
  SlIconButton,
  SlTooltip,
  SlSwitch
} from "../../../../_snowpack/pkg/@shoelace-style/shoelace/dist/react.js";
import FileInputButton from "../../Common/FileInputButton.js";
import Container from "../../Common/Container.js";
import {AppContext} from "../../App/App.js";
import {POLICY_TYPES} from "../../../constants/index.js";
export default () => {
  const {user_id} = useParams();
  const {token, showAlert} = useContext(AppContext);
  const [state, send] = useMachine(userDetailMachine, {
    services: {
      getUserID() {
        return user_id ? Promise.resolve({user: {id: user_id}}) : Promise.reject();
      }
    },
    actions: {
      showError: (context, event) => {
        const message = event.data.message;
        showAlert({type: "danger", message});
      }
    }
  });
  const getFileTypeIcon = (fileType = "") => {
    if (fileType.includes("image")) {
      return "file-earmark-image";
    }
    if (fileType.includes("pdf")) {
      return "file-earmark-pdf";
    }
    return "file-earmark-text";
  };
  const formatDate = (isoDate) => dayjs(isoDate).format("D MMM YYYY HH:mm");
  const formatRelativeDate = (isoDate) => dayjs(isoDate).fromNow();
  const formatFileSize = (bytes) => new Intl.NumberFormat("en-EN", {
    style: "unit",
    unit: "megabyte",
    maximumFractionDigits: 1
  }).format(parseFloat(bytes) / 1e3 / 1e3);
  return /* @__PURE__ */ React.createElement(Container, {
    className: "screen user-detail-screen",
    isLoading: state.hasTag("loading")
  }, /* @__PURE__ */ React.createElement("header", null, /* @__PURE__ */ React.createElement("h2", {
    className: "title"
  }, state.context.user.last_name, " ", state.context.user.first_name)), POLICY_TYPES.map((policy) => {
    return /* @__PURE__ */ React.createElement("section", {
      key: policy.type
    }, /* @__PURE__ */ React.createElement("header", {
      style: {borderBottom: "none"}
    }, /* @__PURE__ */ React.createElement("h2", null, policy.title), /* @__PURE__ */ React.createElement(SlButton, {
      size: "small",
      onClick: (_) => send("EDIT_POLICY", {
        data: {
          policy: {type: policy.type, insurance_company: ""}
        }
      })
    }, "Dodaj polico")), state.context.policies.filter((p) => p.type === policy.type).map((policy2) => {
      return /* @__PURE__ */ React.createElement(Fragment, {
        key: policy2.id
      }, /* @__PURE__ */ React.createElement("header", null, /* @__PURE__ */ React.createElement("h3", {
        className: "title"
      }, policy2.insurance_company || "Neznano"), /* @__PURE__ */ React.createElement("div", {
        className: "actions"
      }, /* @__PURE__ */ React.createElement(FileInputButton, {
        buttonProps: {size: "small"},
        buttonLabel: "Dodaj datoteke",
        onInput: (files) => send("ADD_FILES_TO_POLICY", {
          data: {policy: policy2, files}
        })
      }), /* @__PURE__ */ React.createElement(SlButtonGroup, null, /* @__PURE__ */ React.createElement(SlButton, {
        size: "small",
        onClick: (_) => send("CONFIRM_REMOVE_POLICY", {
          data: {policy: policy2}
        })
      }, "Odstrani polico"), /* @__PURE__ */ React.createElement(SlButton, {
        size: "small",
        onClick: (_) => send("EDIT_POLICY", {data: {policy: policy2}})
      }, "Uredi polico")))), policy2.files?.length ? /* @__PURE__ */ React.createElement("table", {
        className: "table files-table"
      }, /* @__PURE__ */ React.createElement("tbody", null, policy2.files.map((file) => /* @__PURE__ */ React.createElement("tr", {
        key: file.storagePath
      }, /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement(SlIcon, {
        name: getFileTypeIcon(file.type)
      })), /* @__PURE__ */ React.createElement("td", null, file.name), /* @__PURE__ */ React.createElement("td", null, formatFileSize(file.size)), /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement(SlTooltip, {
        content: formatDate(file.created_at)
      }, /* @__PURE__ */ React.createElement("span", null, formatRelativeDate(file.created_at)))), /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement("div", {
        className: "actions"
      }, /* @__PURE__ */ React.createElement(SlSwitch, {
        size: "small",
        variant: "success",
        checked: !!file.isPolicy,
        onSlChange: (_) => send("TOGGLE_IS_POLICY", {
          data: {file, policy: policy2}
        })
      }, file.isPolicy ? "Polica" : "Ni polica"), /* @__PURE__ */ React.createElement(SlTooltip, {
        content: "Prenesi datoteko"
      }, /* @__PURE__ */ React.createElement(SlIconButton, {
        onClick: (_) => send("DOWNLOAD_FILE", {
          data: {file}
        }),
        name: "cloud-arrow-down",
        label: "Prenesi datoteko"
      })), /* @__PURE__ */ React.createElement(SlTooltip, {
        content: "Odstrani datoteko"
      }, /* @__PURE__ */ React.createElement(SlIconButton, {
        onClick: (_) => send("CONFIRM_REMOVE_FILE", {
          data: {file, policy: policy2}
        }),
        name: "trash",
        label: "Odstrani datoteko"
      })))))))) : /* @__PURE__ */ React.createElement("div", {
        className: "empty"
      }, "Ta polica nima datotek."));
    }), state.context.policies.filter((p) => p.type === policy.type).length === 0 && /* @__PURE__ */ React.createElement("section", null, /* @__PURE__ */ React.createElement("div", {
      className: "empty",
      style: {fontSize: 14}
    }, "Uporabnik nima polic.")));
  }), /* @__PURE__ */ React.createElement(SlDialog, {
    label: state.context.policyInput.id ? "Uredi polico" : "Dodaj polico",
    class: "dialog-overview",
    open: state.matches("editingPolicy"),
    onSlRequestClose: (e) => {
      e.preventDefault();
      send("STOP");
    }
  }, /* @__PURE__ */ React.createElement("form", {
    className: "form",
    onSubmit: (e) => {
      e.preventDefault();
      send("SAVE_POLICY");
    }
  }, /* @__PURE__ */ React.createElement(SlInput, {
    type: "text",
    label: "Zavarovalnica",
    value: state.context.policyInput.insurance_company,
    onInput: (e) => send("UPDATE_POLICY_INPUT", {
      data: {policy: {insurance_company: e.target.value}}
    })
  }), /* @__PURE__ */ React.createElement("div", {
    className: "actions"
  }, /* @__PURE__ */ React.createElement(SlButton, {
    onClick: (_) => send("STOP")
  }, "Prekliči"), /* @__PURE__ */ React.createElement(SlButton, {
    type: "submit",
    variant: "success"
  }, "Shrani")))), /* @__PURE__ */ React.createElement(SlDialog, {
    label: "Odstrani polico",
    class: "dialog-overview",
    open: state.matches("removingPolicy"),
    onSlRequestClose: (e) => {
      e.preventDefault();
      send("STOP");
    }
  }, /* @__PURE__ */ React.createElement("form", {
    className: "form",
    onSubmit: (e) => {
      e.preventDefault();
      send("REMOVE_POLICY");
    }
  }, /* @__PURE__ */ React.createElement("p", null, "Z odstranitvijo police se bodo odstranile tudi vse datoteke, vezane na polico. Ta akcija je nepovrnljiva.", /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement("strong", null, "Ali ste prepričani, da želite odstraniti polico?")), /* @__PURE__ */ React.createElement("div", {
    className: "actions"
  }, state.matches("removingPolicy.confirming") && /* @__PURE__ */ React.createElement(SlButton, {
    onClick: (_) => send("STOP")
  }, "Prekliči"), /* @__PURE__ */ React.createElement(SlButton, {
    type: "submit",
    variant: "danger",
    loading: state.matches("removingPolicy.removing")
  }, "Odstrani")))), /* @__PURE__ */ React.createElement(SlDialog, {
    label: "Odstrani datoteko",
    class: "dialog-overview",
    open: state.matches("removingFileFromPolicy"),
    onSlRequestClose: (e) => {
      e.preventDefault();
      send("STOP");
    }
  }, /* @__PURE__ */ React.createElement("form", {
    className: "form",
    onSubmit: (e) => {
      e.preventDefault();
      send("REMOVE_FILE");
    }
  }, /* @__PURE__ */ React.createElement("p", null, "Ali ste prepričani, da želite odstraniti datoteko", state.context.fileInput.name, "?"), /* @__PURE__ */ React.createElement("div", {
    className: "actions"
  }, state.matches("removingFileFromPolicy.confirming") && /* @__PURE__ */ React.createElement(SlButton, {
    onClick: (_) => send("STOP")
  }, "Prekliči"), /* @__PURE__ */ React.createElement(SlButton, {
    variant: "danger",
    type: "submit",
    loading: state.matches("removingFileFromPolicy.removing")
  }, "Odstrani")))));
};
